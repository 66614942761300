

















































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import DocumentAdd16 from '@carbon/icons-vue/es/document--add/16'
import Download16 from '@carbon/icons-vue/es/download/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    GenericModalDelete,
    TrashCan16,
    Download16,
    DocumentAdd16
  }
})
export default class EventSubscriptionAttachmentsTable extends Mixins(FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  @Prop({ required: true, type: Boolean }) validExamDocs!: boolean

  filteredData = []
  attachments = []

  deleteModalIsOpened = false

  selectedDocument = {
    id: ''
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`event/${this.eventId}/subscription/${this.subscriptionId}/attachment`)
      .then(response => {
        this.filteredData = this.attachments = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  postSubmit (message: string) {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: message,
      type: 'success'
    }

    this.setFlashMessage(flashMessage)
    this.fetch()
  }
}
