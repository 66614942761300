







































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CieloInstructionsModal extends Vue {
  @Prop({ default: false }) visible!: boolean

  pixButton = 'Copiar código PIX'
  pixCode = '00020126360014BR.GOV.BCB.PIX0114016403040001865204000053039865406994.005802BR5903SBQ6008SAOPAULO62150511TAA21AJUSTE630425A4'

  copyPixCode () {
    const field = document.getElementById('pix-code') as HTMLInputElement

    field.select()
    field.setSelectionRange(0, 1024)

    document.execCommand('copy')

    this.pixButton = 'Código copiado!'
    setTimeout(() => { this.pixButton = 'Copiar código PIX' }, 5000)
  }
}
