import { render, staticRenderFns } from "./PixInstructionsModal.vue?vue&type=template&id=4e68ab97&scoped=true&"
import script from "./PixInstructionsModal.vue?vue&type=script&lang=ts&"
export * from "./PixInstructionsModal.vue?vue&type=script&lang=ts&"
import style0 from "./PixInstructionsModal.vue?vue&type=style&index=0&id=4e68ab97&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e68ab97",
  null
  
)

export default component.exports