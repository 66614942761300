








































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import fileDownload from 'js-file-download'
import { namespace } from 'vuex-class'
import snakeCaseKeys from 'snakecase-keys'

import PermissionsMixin from '@/mixins/permissionsMixin'
import DataCard from '@/partials/components/DataCard.vue'
import EventAgenda from '@/partials/components/Events/Agenda.vue'
import EventSponsorsLogo from '@/partials/components/Events/SponsorsLogo.vue'
import OperationsComponent from '@/partials/components/Events/Subscriptions/Operations.vue'
import SubscriptionAttachmentsTable from '@/partials/tables/Events/Subscriptions/Attachments/index.vue'
import CieloInstructionsModal from '@/partials/modals/Events/CieloInstructionsModal.vue'
import PixInstructionsModal from '@/partials/modals/Events/PixInstructionsModal.vue'

const eventsPermissionsModule = namespace('eventsPermissions')

type BtnModuleTypes = 'api.event.reports.earnings.csv' | 'api.event.reports.exam-payment-attempts.csv'

@Component({
  components: {
    CieloInstructionsModal,
    PixInstructionsModal,
    SubscriptionAttachmentsTable,
    OperationsComponent,
    EventSponsorsLogo,
    EventAgenda,
    DataCard
  }
})
export default class EventShow extends Mixins(PermissionsMixin) {
  @eventsPermissionsModule.Getter getEventUserId!: (eventId: string) => string
  eventConfig = ''
  subscription = {} as any
  hasSubscription = false
  event = {
    title: '',
    id: ''
  }

  examPayment = {} as any
  hasExamPayment = false
  hasExamPaymentFullfilled = false
  showPix = false
  acceptingExamPayments = false

  reportEventSubscriptionTypeId = 'all'

  examPaymentStatus = {
    kind: 'info',
    title: 'TAA-SBQ',
    subtitle: 'Realize o pagamento da taxa do TAA-SBQ no botão abaixo. Habilite popups no seu navegador.'
  }

  cieloInstructionsIsOpened = false
  pixInstructionsIsOpened = false

  created () {
    axios.get(`/event/${this.$route.params.id}`)
      .then(response => {
        this.event = camelCaseKeys(response.data.data, { deep: true })
      })

    this.fetchPurchasedTicket()
  }

  redirectToPayment () {
    axios.post(`/event/${this.event.id}/exam/payment/attempt`, snakeCaseKeys(this.paymentAttemptForm))
      .then(response => {
        this.cieloInstructionsIsOpened = false
        this.$nextTick(() => {
          window.open('https://cielolink.com.br/3hUJXDh', '_newtab')
        })
      })
  }

  private get paymentAttemptForm () {
    return {
      eventId: this.event.id,
      eventUserId: this.getEventUserId(this.event.id)
    }
  }

  fetchPurchasedTicket () {
    const eventUserId = this.getEventUserId(this.$route.params.id)

    axios.get(`/event/${this.$route.params.id}/subscriptions/${eventUserId}/purchased`)
      .then(response => {
        this.subscription = camelCaseKeys(response.data.data, { deep: true })
        this.hasSubscription = true
        this.handleExamPaymentStatus()
      })
      .catch((err) => {
        const response = err.response

        if (response.data.status === 404) {
          this.hasSubscription = false
          this.subscription = {}
        }
      })
  }

  handleExamPaymentStatus () {
    if (this.subscription.subscriptionType.audience === 'public') {
      if (this.acceptingExamPayments) {
        this.examPaymentStatus.title = 'Prezado médico:'
        this.examPaymentStatus.subtitle = 'Candidatos ao exame TAA-SBQ que não fizeram especialização em serviço credenciado pela SBQ e tiverem interesse em realizar o exame devem seguir as instruções:'
      } else {
        this.examPaymentStatus.title = 'Prezado médico:'
        this.examPaymentStatus.subtitle = 'Inscrições para o TAA-SBQ 2021 ENCERRADAS.'
      }

      this.showPix = true
    }

    if (this.subscription.eventUser.examPayments[0] !== undefined) {
      this.examPayment = this.subscription.eventUser.examPayments[0]
      this.hasExamPayment = Object.keys(this.examPayment).length > 0 && this.examPayment.constructor === Object
      this.hasExamPaymentFullfilled = (this.hasExamPayment && this.examPayment.fullfilled === true)

      if (this.examPayment.fullfilled === false) {
        this.examPaymentStatus.kind = 'warning'
        this.examPaymentStatus.title = 'AGUARDE:'
        this.examPaymentStatus.subtitle = 'PAGAMENTO TAA-SBQ PENDENTE DE CONFIRMAÇÃO.'
      } else {
        this.examPaymentStatus.kind = 'success'
        this.examPaymentStatus.title = 'SUCESSO:'
        this.examPaymentStatus.subtitle = 'VOCÊ ESTÁ INSCRITO NO TAA-SBQ. ENVIE A DOCUMENTAÇÃO OBRIGATÓRIA!'
        this.showPix = false
      }
    }
  }

  openPixModal () {
    axios.post(`/event/${this.event.id}/exam/payment/attempt`, snakeCaseKeys(this.paymentAttemptForm))
      .then(response => {
        this.pixInstructionsIsOpened = true
      })
  }

  handleAction (btnModule: BtnModuleTypes, btnEventSubscriptionTypeId: string) {
    const actionDict = {
      'api.event.reports.earnings.csv': this.reportEarningsDownload,
      'api.event.reports.subscriptions.csv': this.reportSubscriptionsDownload,
      'api.event.reports.exam-payment-attempts.csv': this.reportExamPaymentDownload
    }
    this.reportEventSubscriptionTypeId = btnEventSubscriptionTypeId

    actionDict[btnModule]()
  }

  reportEarningsDownload () {
    axios.get(`/event/${this.event.id}/reports/earnings/csv`, {
      responseType: 'blob'
    })
      .then(response => {
        const filenameHeader = response.headers['content-disposition']

        const filename = filenameHeader.match(/filename=(.+)/)
        fileDownload(response.data, filename[1] ?? 'relatorio.csv')
      })
  }

  reportSubscriptionsDownload () {
    axios.get(`/event/${this.event.id}/reports/subscriptions/csv/${this.reportEventSubscriptionTypeId}`, {
      responseType: 'blob'
    })
      .then(response => {
        const filenameHeader = response.headers['content-disposition']

        const filename = filenameHeader.match(/filename=(.+)/)
        fileDownload(response.data, filename[1] ?? 'relatorio-inscritos.csv')
      })
  }

  reportExamPaymentDownload () {
    axios.get(`/event/${this.event.id}/reports/exam-payment-attempts/csv`, {
      responseType: 'blob'
    })
      .then(response => {
        const filenameHeader = response.headers['content-disposition']

        const filename = filenameHeader.match(/filename=(.+)/)
        fileDownload(response.data, filename[1] ?? 'relatorio-prova.csv')
      })
  }

  editEventConfig () {
    this.$router.push(this.eventConfig)
  }
}
