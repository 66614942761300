











































import { Component, Prop, Vue } from 'vue-property-decorator'
import camelCaseKeys from 'camelcase-keys'

import axios from 'axios'

@Component
export default class EventAgenda extends Vue {
  @Prop({ required: true, type: String }) eventId!: string
  modules: any = []

  created () {
    axios.get(`/event/${this.eventId}/activity/agenda`)
      .then(response => {
        this.modules = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  formatName (name: string) {
    if (name.startsWith('::')) {
      return name.replace('::', '').replaceAll(',', '\n')
    }

    return name
  }
}
