


















import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class EventSponsorsLogo extends Vue {
  @Prop({ required: true, type: String }) eventId!: string
  sponsors: Array<any> = []

  created () {
    axios.get(`/event/${this.eventId}/sponsors`)
      .then(response => {
        this.sponsors = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
